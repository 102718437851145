import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { AuthUtils } from 'app/core/auth/auth.utils';
import { UserService } from 'app/core/user/user.service';
// import { catchError, Observable, of, switchMap, throwError } from 'rxjs';
import { signUp, signIn, fetchUserAttributes, signOut, fetchAuthSession } from 'aws-amplify/auth';
import { catchError, Observable, of, switchMap, throwError } from 'rxjs';
import { cloneDeep } from 'lodash-es';
import { jwtDecode } from "jwt-decode";


@Injectable({ providedIn: 'root' })
export class AuthService {
    private _authenticated: boolean = false;
    private _httpClient = inject(HttpClient);
    private _userService = inject(UserService);

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Setter & getter for access token
     */
    set accessToken(token: string) {
        localStorage.setItem('accessToken', token);
    }

    get accessToken(): string {
        return localStorage.getItem('accessToken') ?? '';
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Forgot password
     *
     * @param email
     */
    forgotPassword(email: string)//: Observable<any>
    {
        return this._httpClient.post('api/auth/forgot-password', email);
    }

    /**
     * Reset password
     *
     * @param password
     */
    resetPassword(password: string)//: Observable<any>
    {
        return this._httpClient.post('api/auth/reset-password', password);
    }

    /**
     * Sign in
     *
     * @param credentials
     */
    async signIn(credentials: { email: string; password: string })//: Observable<any>
    {

        try {
            await signOut();

            var response = await signIn({ username: credentials.email, password: credentials.password });
            console.log('SIGNIN RESPONSE:' + JSON.stringify(response));
            if (response.isSignedIn) {
                var currentUser = await this.getCurrentUser();

                // Set the authenticated flag to true
                this._authenticated = true;

                // Store the user on the user service
                this._userService.user = currentUser;

                return [
                    200,
                    {
                        user: cloneDeep(currentUser),
                        accessToken: this.accessToken,
                        tokenType: 'bearer',
                    },
                ];
            }
        } catch (error) {
            console.log(JSON.stringify(error));
        }

        // Invalid credentials
        return [
            404,
            false,
        ];

    }


    signInWithToken(token) {

        var isExpired = AuthUtils.isTokenExpired(token);
        console.log('IsExpired=' + isExpired);

        if (isExpired) {

            // Invalid token
            return [
                401,
                {
                    error: 'Invalid token',
                },
            ];
        }
        else {
            var decoded = jwtDecode(token);
            var user = JSON.parse(JSON.stringify(decoded));
            var accountDetails:any; 
            this.getAccountDetails(user.email).then((item=>{
                accountDetails=item;
            }));
            

            var currentUser = {
                id: user.sub,
                account_id: accountDetails.accountId,
                account_config: accountDetails.accountConfig,
                name: user.given_name,
                email: user.email,
                given_name: user.given_name,
                family_name: user.family_name,
                groups: user["cognito:groups"].toString(),
                avatar: '',
                //avatar: 'assets/images/avatars/male-01.jpg',
                status: 'online',
            };

            return [
                200,
                {
                    user: cloneDeep(currentUser),
                    accessToken: this.accessToken,
                    tokenType: 'bearer',
                },
            ];
        }

    }


    /**
     * Sign in using the access token
     */
    signInUsingToken(): Observable<any> {

        var isExpired = AuthUtils.isTokenExpired(this.accessToken);
        console.log('IsExpired=' + isExpired);
        if (isExpired) {

            return of(false);
        }
        else {
            var decoded = jwtDecode(this.accessToken);
            var user = JSON.parse(JSON.stringify(decoded));
            var accountDetails:any; 
            this.getAccountDetails(user.email).then((item=>{
                accountDetails=item;

                var currentUser = {
                    id: user.sub,
                    account_id: accountDetails.accountId,
                    account_config: accountDetails.accountConfig,
                    name: user.given_name,
                    email: user.email,
                    given_name: user.given_name,
                    family_name: user.family_name,
                    groups: user["cognito:groups"].toString(),
                    avatar: '',
                    //avatar: 'assets/images/avatars/male-01.jpg',
                    status: 'online',
                };
    
                this._authenticated= true;
    
                this._userService.user = currentUser;
    
                
            }));
            return of(true);
        }
    }

    /**
     * Sign out
     */
    async signOut() {

        await signOut({ global: true });

        // Remove the access token from the local storage
        localStorage.removeItem('accessToken');

        // Set the authenticated flag to false
        this._authenticated = false;

        return true;
    }

    /**
     * Sign up
     *
     * @param user
     */
    async signUp(user: { firstname: string; lastname: string; email: string; password: string; }) {
        try {
            var signUpResponse = await signUp({
                username: user.email,
                password: user.password,
                options: {
                    userAttributes: {
                        email: user.email,
                        given_name: user.firstname,
                        family_name: user.lastname
                        //phone_number // E.164 number convention
                    },
                    // optional
                    autoSignIn: true // or SignInOptions e.g { authFlowType: "USER_SRP_AUTH" }
                }


            });

            console.log(JSON.stringify(signUpResponse));

            return [
                200,
                true,
            ];
        } catch (error) {
            console.log('error signing up:', error);
            return [
                404,
                false,
            ];
        }
    }

    /**
     * Unlock session
     *
     * @param credentials
     */
    unlockSession(credentials: { email: string; password: string }): Observable<any> {
        return this._httpClient.post('api/auth/unlock-session', credentials);
    }

    /**
     * Check the authentication status
     */
    check(): Observable<boolean> {
        // Check if the user is logged in
        if (this._authenticated) {
            console.log('1');
            return of(true);
        }

        // Check the access token availability
        else if (!this.accessToken) {
            console.log('2');
            return of(false);
        }
        // Check the access token expire date
        else if (AuthUtils.isTokenExpired(this.accessToken)) {
            console.log('3');
            return of(false);
        }
        else {
            console.log('4');
            // If the access token exists, and it didn't expire, sign in using it
            return this.signInUsingToken();
        }
    }


    //Private Methods
    async getCurrentUser() {

        var groups = (await fetchAuthSession()).tokens?.accessToken.payload['cognito:groups'];

        const accessToken = (await fetchAuthSession()).tokens?.idToken.toString();
        var user = await fetchUserAttributes();
        console.log(JSON.stringify(user));

        //TODO: get agent Rel8 account Id
        var accountDetails = await this.getAccountDetails(user.email);
        var currentUser = {
            id: user.sub,
            account_id: accountDetails.accountId,
            account_config: accountDetails.accountConfig,
            name: user.given_name,
            email: user.email,
            given_name: user.given_name,
            family_name: user.family_name,
            groups: groups.toString(),
            avatar: '',
            //avatar: 'assets/images/avatars/male-01.jpg',
            status: 'online',
        };
        this.accessToken = accessToken;
        return currentUser;
    }

    async getAccountDetails(userEmail:string){
        var _accountId:string;
        var _accountConfig:any;

        if(userEmail.endsWith('rel8.cx')){
            _accountId = '4fabac70-c077-4fd1-925b-8005a57ec84c';
            _accountConfig = {
                AccountId: '4fabac70-c077-4fd1-925b-8005a57ec84c',
                AccountAlias: 'rel8-prd',
                AwsAccountId: '045493394446',
                AwsRegion: 'eu-west-2',
                AwsAssumeRoleArn: 'arn:aws:iam::045493394446:role/rel8-prd-service-role',
                AwsS3BucketName: 'rel8-prd-connect-045493394446-eu-west-2',
                AwsConnectInstanceId: "4fabac70-c077-4fd1-925b-8005a57ec84c"
            };
        }
        else{
            _accountId = '013524bb-9268-4a2f-be8a-b2060b58c27b';
            _accountConfig = {
                AccountId: '013524bb-9268-4a2f-be8a-b2060b58c27b',
                AccountAlias: 'rel8-ontree-sbx',
                AwsAccountId: '567179353136',
                AwsRegion: 'eu-west-2',
                AwsAssumeRoleArn: 'arn:aws:iam::567179353136:role/c3-rel8-ontree-sbx-AccessRole',
                AwsS3BucketName: 'rel8-ontree-sbx-connect-567179353136-eu-west-2',
                AwsConnectInstanceId: "cbc38c09-d3a9-4108-900a-b65e1e094bcf"
            };
        }
        return {
            accountId: _accountId,
            accountConfig: _accountConfig
        };
    }

}

