import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FuseMockApiService, FuseMockApiUtils } from '@fuse/lib/mock-api';
import {
    // securityProfiles as securityProfilesData, 
    // routingProfiles as routingProfilesData,
    // users as usersData, 
    tags as tagsData, usersHierarcies as usersHierarciesData
} from 'app/mock-api/apps/manage-users/users/data';
import { assign, cloneDeep } from 'lodash-es';
import { map } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class ManageUsersMockApi {
    // private _routingProfiles: any[];
    // private _securityProfiles: any[];
    // private _users: any[];
    private _tags: any[] = tagsData;
    private _usersHierarcies: any[] = usersHierarciesData;




    /**
     * Constructor
     */
    constructor(private _httpClient: HttpClient, private _fuseMockApiService: FuseMockApiService) {
        
        // Register Mock API handlers
        this.registerHandlers();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    ngOnInit() {

    }
    /**
     * Register Mock API handlers
     */
    registerHandlers(): void {
        // -----------------------------------------------------------------------------------------------------
        // @ routingProfiles - GET
        // -----------------------------------------------------------------------------------------------------
        // this._fuseMockApiService
        //     .onGet('api/apps/manage-users/users/routingProfiles')
        //     .reply(() => [200, cloneDeep(this._routingProfiles)]);

        // -----------------------------------------------------------------------------------------------------
        // @ securityProfiles - GET
        // -----------------------------------------------------------------------------------------------------
        // this._fuseMockApiService
        //     .onGet('api/apps/manage-users/users/securityProfiles')
        //     .reply(() => [200, cloneDeep(this._securityProfiles)]);


        // -----------------------------------------------------------------------------------------------------
        // @ userHierarcies - GET
        // -----------------------------------------------------------------------------------------------------
        this._fuseMockApiService
            .onGet('api/apps/manage-users/users/usersHierarcies')
            .reply(() => [200, cloneDeep(this._usersHierarcies)]);





        // getSearchData(searchTerm: string): Observable<any> {
        //     return this.httpClient.post(this.url, { query_string: searchTerm });
        // }

        // function getUsers(){
        //     try {


        //         console.log('POST call succeeded: ', this._users);
        //     } catch (error) {
        //         console.log('POST call failed: ', error);
        //         return;
        //     }
        // }

        // -----------------------------------------------------------------------------------------------------
        // @ users - GET
        // -----------------------------------------------------------------------------------------------------
        // this._fuseMockApiService
        //     .onGet('api/apps/manage-users/users/users', 300)
        //     .reply(({ request }) => {
        //         // Get available queries
        //         const search = request.params.get('search');
        //         const sort = request.params.get('sort') || 'name';
        //         const order = request.params.get('order') || 'asc';
        //         const page = parseInt(request.params.get('page') ?? '1', 10);
        //         const size = parseInt(request.params.get('size') ?? '10', 10);

        //         // Clone the users




        //         console.log('AK-' + JSON.stringify(this._users));

        //         let users: any[] | null = cloneDeep(this._users);

        //         // Sort the users
        //         if (sort === 'id' || sort === 'name' || sort === 'active') {
        //             users.sort((a, b) => {
        //                 const fieldA = a[sort].toString().toUpperCase();
        //                 const fieldB = b[sort].toString().toUpperCase();
        //                 return order === 'asc' ? fieldA.localeCompare(fieldB) : fieldB.localeCompare(fieldA);
        //             });
        //         }
        //         else {
        //             users.sort((a, b) => order === 'asc' ? a[sort] - b[sort] : b[sort] - a[sort]);
        //         }

        //         // If search exists...
        //         if (search) {
        //             // Filter the users
        //             users = users.filter(contact => contact.name && contact.name.toLowerCase().includes(search.toLowerCase()));
        //         }

        //         // Paginate - Start
        //         const usersLength = users.length;

        //         // Calculate pagination details
        //         const begin = page * size;
        //         const end = Math.min((size * (page + 1)), usersLength);
        //         const lastPage = Math.max(Math.ceil(usersLength / size), 1);

        //         // Prepare the pagination object
        //         let pagination = {};

        //         // If the requested page number is bigger than
        //         // the last possible page number, return null for
        //         // users but also send the last possible page so
        //         // the app can navigate to there
        //         if (page > lastPage) {
        //             users = null;
        //             pagination = {
        //                 lastPage,
        //             };
        //         }
        //         else {
        //             // Paginate the results by size
        //             users = users.slice(begin, end);

        //             // Prepare the pagination mock-api
        //             pagination = {
        //                 length: usersLength,
        //                 size: size,
        //                 page: page,
        //                 lastPage: lastPage,
        //                 startIndex: begin,
        //                 endIndex: end - 1,
        //             };
        //         }

        //         // Return the response
        //         return [
        //             200,
        //             {
        //                 users,
        //                 pagination,
        //             },
        //         ];
        //     });

        // -----------------------------------------------------------------------------------------------------
        // @ user - GET
        // -----------------------------------------------------------------------------------------------------
        // this._fuseMockApiService
        //     .onGet('api/apps/manage-users/users/user')
        //     .reply(({ request }) => {
        //         // Get the id from the params
        //         const id = request.params.get('id');

        //         // Clone the users
        //         const users = cloneDeep(this._users);

        //         // Find the user
        //         const user = users.find(item => item.id === id);

        //         // Return the response
        //         return [200, user];
        //     });

        // -----------------------------------------------------------------------------------------------------
        // @ user - POST
        // -----------------------------------------------------------------------------------------------------
        // this._fuseMockApiService
        //     .onPost('api/apps/manage-users/users/user')
        //     .reply(() => {
        //         // Generate a new user
        //         const newUser = {
        //             id: FuseMockApiUtils.guid(),
        //             category: '',
        //             name: 'A New user',
        //             description: '',
        //             tags: [],
        //             sku: '',
        //             barcode: '',
        //             brand: '',
        //             vendor: '',
        //             stock: '',
        //             reserved: '',
        //             cost: '',
        //             basePrice: '',
        //             taxPercent: '',
        //             price: '',
        //             weight: '',
        //             thumbnail: '',
        //             images: [],
        //             active: false,
        //         };

        //         // Unshift the new user
        //         this._users.unshift(newUser);

        //         // Return the response
        //         return [200, newUser];
        //     });

        // -----------------------------------------------------------------------------------------------------
        // @ user - PATCH
        // -----------------------------------------------------------------------------------------------------
        // this._fuseMockApiService
        //     .onPatch('api/apps/manage-users/users/user')
        //     .reply(({ request }) => {
        //         // Get the id and user
        //         const id = request.body.id;
        //         const user = cloneDeep(request.body.user);

        //         // Prepare the updated user
        //         let updatedUser = null;

        //         // Find the user and update it
        //         this._users.forEach((item, index, users) => {
        //             if (item.id === id) {
        //                 // Update the user
        //                 users[index] = assign({}, users[index], user);

        //                 // Store the updated user
        //                 updatedUser = users[index];
        //             }
        //         });

        //         // Return the response
        //         return [200, updatedUser];
        //     });

        // -----------------------------------------------------------------------------------------------------
        // @ user - DELETE
        // -----------------------------------------------------------------------------------------------------
        // this._fuseMockApiService
        //     .onDelete('api/apps/manage-users/users/user')
        //     .reply(({ request }) => {
        //         // Get the id
        //         const id = request.params.get('id');

        //         // Find the user and delete it
        //         this._users.forEach((item, index) => {
        //             if (item.id === id) {
        //                 this._users.splice(index, 1);
        //             }
        //         });

        //         // Return the response
        //         return [200, true];
        //     });

        // -----------------------------------------------------------------------------------------------------
        // @ Tags - GET
        // -----------------------------------------------------------------------------------------------------
        this._fuseMockApiService
            .onGet('api/apps/manage-users/users/tags')
            .reply(() => [200, cloneDeep(this._tags)]);

        // -----------------------------------------------------------------------------------------------------
        // @ Tags - POST
        // -----------------------------------------------------------------------------------------------------
        this._fuseMockApiService
            .onPost('api/apps/manage-users/users/tag')
            .reply(({ request }) => {
                // Get the tag
                const newTag = cloneDeep(request.body.tag);

                // Generate a new GUID
                newTag.id = FuseMockApiUtils.guid();

                // Unshift the new tag
                this._tags.unshift(newTag);

                // Return the response
                return [200, newTag];
            });

        // -----------------------------------------------------------------------------------------------------
        // @ Tags - PATCH
        // -----------------------------------------------------------------------------------------------------
        this._fuseMockApiService
            .onPatch('api/apps/manage-users/users/tag')
            .reply(({ request }) => {
                // Get the id and tag
                const id = request.body.id;
                const tag = cloneDeep(request.body.tag);

                // Prepare the updated tag
                let updatedTag = null;

                // Find the tag and update it
                this._tags.forEach((item, index, tags) => {
                    if (item.id === id) {
                        // Update the tag
                        tags[index] = assign({}, tags[index], tag);

                        // Store the updated tag
                        updatedTag = tags[index];
                    }
                });

                // Return the response
                return [200, updatedTag];
            });

        // -----------------------------------------------------------------------------------------------------
        // @ Tag - DELETE
        // -----------------------------------------------------------------------------------------------------
        // this._fuseMockApiService
        //     .onDelete('api/apps/manage-users/users/tag')
        //     .reply(({ request }) => {
        //         // Get the id
        //         const id = request.params.get('id');

        //         // Find the tag and delete it
        //         this._tags.forEach((item, index) => {
        //             if (item.id === id) {
        //                 this._tags.splice(index, 1);
        //             }
        //         });

        //         // Get the users that have the tag
        //         const usersWithTag = this._users.filter(user => user.tags.indexOf(id) > -1);

        //         // Iterate through them and delete the tag
        //         usersWithTag.forEach((user) => {
        //             user.tags.splice(user.tags.indexOf(id), 1);
        //         });

        //         // Return the response
        //         return [200, true];
        //     });


    }
}
