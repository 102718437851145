/* eslint-disable */
import { FuseNavigationItem } from '@fuse/components/navigation';

export const agentNavigation: FuseNavigationItem[] = [
    {
        id      : 'dashboards',
        title   : 'Dashboards',
        subtitle: 'Contact Center Dashboard',
        type    : 'group',
        icon    : 'heroicons_outline:home',
        children: [
            {
                id   : 'dashboards.project',
                title: 'Dashboard',
                type : 'basic',
                icon : 'mat_outline:space_dashboard',
                link : '/dashboards/agent',
                badge: {
                    title  : '14',
                    classes: 'px-2 bg-primary-600 text-white rounded-full',
                },
            }
                  

       
            // {
            //    id   : 'dashboards.project',
            //    title: 'Analytics & Optimization',
            //    type : 'collapsable',
            //    icon : 'heroicons_outline:presentation-chart-bar',
            //    link : '#',
            //     children: [
            //        {
            //            id        : 'apps.help-center.home',
            //            title     : 'Realtime Metrics',
            //            type      : 'basic',
            //            link      : 'apps/contacts',
            //            exactMatch: true,
            //        },
            //     ],
                
                
            //}
            
        ],
    },
    {
        id      : 'engagements',
        title   : 'CONTACT CENTER',
        subtitle: 'Live, past engagements and more...',
        type    : 'group',
        icon    : 'heroicons_outline:home',
        children: [
            {
                id   : 'cxhub.main',
                title: 'CX Control Hub',
                type : 'basic',
                icon : 'mat_outline:all_inclusive',
                link : '/cc/cx-hub',
                badge: {
                    title  : '144',
                    classes: 'px-2 bg-warn-600 text-white rounded-full',
                },
            },
            {
                id   : 'engagements.main',
                title: 'Engagements',
                type : 'basic',
                icon : 'heroicons_outline:chat-bubble-left-right',
                link : '/cc/engagements'
            }
          
        ],
    }
];

export const managerNavigation: FuseNavigationItem[] = [
    {
        id      : 'dashboards',
        title   : 'Dashboard',
        subtitle: 'Contact Center Dashboard',
        type    : 'group',
        icon    : 'heroicons_outline:home',
        children: [
            {
                id   : 'dashboards.project',
                title: 'Dashboard',
                type : 'basic',
                icon : 'mat_outline:space_dashboard',
                link : '/dashboards/manager',
                badge: {
                    title  : '7',
                    classes: 'px-2 bg-amber-600 text-white rounded-full',
                },
            },
                  

       
            // {
            //    id   : 'dashboards.project',
            //    title: 'Analytics & Optimization',
            //    type : 'collapsable',
            //    icon : 'heroicons_outline:presentation-chart-bar',
            //    link : '#',
            //     children: [
            //        {
            //            id        : 'apps.help-center.home',
            //            title     : 'Realtime Metrics',
            //            type      : 'basic',
            //            link      : 'apps/contacts',
            //            exactMatch: true,
            //        },
            //     ],
                
                
            //}
            
        ],
    },
    {
        id      : 'engagements',
        title   : 'CONTACT CENTER',
        subtitle: 'Live, past engagements and more...',
        type    : 'group',
        icon    : 'heroicons_outline:home',
        children: [
            {
                id   : 'cxhub.main',
                title: 'CX Control Hub',
                type : 'basic',
                icon : 'mat_outline:all_inclusive',
                link : '/cc/cx-hub',
                badge: {
                    title  : '144',
                    classes: 'px-2 bg-warn-600 text-white rounded-full',
                },
            },
            {
                id   : 'engagements.main',
                title: 'Engagements',
                type : 'basic',
                icon : 'heroicons_outline:chat-bubble-left-right',
                link : '/cc/engagements'
            }
          
        ],
    },
    {
        id      : 'settings',
        title   : 'Operation',
        subtitle: 'Agents, Departments and more',
        type    : 'group',
        icon    : 'heroicons_outline:home',
        children: [
            

            {
                id   : 'settings.user-management.home',
                title: 'Users',
                type : 'basic',
                icon : 'heroicons_outline:user-group',
                link : '/settings/user-management',
                exactMatch: true,
            },

            {
                id   : 'settings.user-management.teams',
                title: 'Teams',
                type : 'basic',
                icon : 'mat_outline:groups',
                link : '/settings/user-management/teams',
                exactMatch: true,
            },

            // 
            // {
            //     id   : 'settings.user-management.permissions',
            //     title: 'Permissions',
            //     type : 'basic',
            //     icon : 'heroicons_outline:shield-check',
            //     link : '/settings/user-management/permissions',
            //     exactMatch: true,
            // },

            // {
            //     // id   : 'settings.user-management',
            //     // title: 'User Management',
            //     // type : 'collapsable',
            //     // icon : 'heroicons_outline:user-group',
            //     // link : '#',
            //     // children: [
             
            //         // {
            //         //     id   : 'settings.user-management.status-management',
            //         //     title: 'Manage Statuses',
            //         //     type : 'basic',
            //         //     link : '/settings/user-management/status-management',
            //         // },
            
            //         // {
            //         //     id   : 'settings.user-management.team-structure',
            //         //     title: 'Team Structure',
            //         //     type : 'basic',
            //         //     link : '/settings/user-management/team-structure',
            //         // }
            //     // ],
            // },

            {
                id      : 'settings.routing',
                title   : 'Routing',
                type    : 'collapsable',
                icon    : 'mat_outline:call_split',
                link : '#',
                children: [
                    // flows are modals to create usecases
                    // {
                    //     id        : 'settings.routing.home',
                    //     title     : 'Flows',
                    //     type      : 'basic',
                    //     link      : '/settings/routing',
                    //     exactMatch: true,
                    // },

                    // Prompts are audio options for calls/chat
                    // {
                    //     id   : 'settings.routing.prompts',
                    //     title: 'Prompts',
                    //     type : 'basic',
                    //     link : '/settings/routing/prompts',
                    // },
                    {
                        id   : 'settings.routing.queues',
                        title: 'Queues',
                        type : 'basic',
                        link : '/settings/routing/queues',
                    },
                    {
                        id   : 'settings.routing.operating-hours',
                        title: 'Hours of operation',
                        type : 'basic',
                        link : '/settings/routing/operating-hours',
                    },
                    // Quick connects are shortcuts to quickly connect to a user or a team
                    // {
                    //     id   : 'settings.routing.transfer-dests',
                    //     title: 'Quick Connects',
                    //     type : 'basic',
                    //     link : '/settings/routing/transfer-destinations',
                    // },
                ],
            },

            {
                id   : 'settings.channels',
                title: 'Channels',
                type : 'basic',
                icon : 'heroicons_solid:arrows-pointing-in',
                link : '/settings/user-management/channels',
                exactMatch: true,
            },

            
            // {
            //     id   : 'apps.file-manager',
            //     title: 'Outbound Campaign',
            //     type : 'basic',
            //     icon : 'heroicons_outline:newspaper',
            //     link : '/apps/file-manager',
            // },
           
          
        ],
    },
    
    {
        id      : 'cm',
        title   : 'Campaign Management',
        subtitle: 'Design Campaigns, Scripts and more...',
        type    : 'group',
        icon    : 'heroicons_outline:home',
        children: [
            
            {
                id   : 'cm.campaigns',
                title: 'Campaigns',
                type : 'basic',
                icon : 'heroicons_outline:cursor-arrow-ripple',
                link : '/cm/campaigns',
            },
            {
                id   : 'cm.segments',
                title: 'Segments',
                type : 'basic',
                icon : 'heroicons_outline:users',
                link : '/cm/segments',
            },
            {
                id   : 'cm.templates',
                title: 'Templates',
                type : 'basic',
                icon : 'heroicons_outline:document-text',
                link : '/cm/templates',
            },
          
        ],
    }
];

export const adminNavigation: FuseNavigationItem[] = [
    {
        id      : 'dashboards',
        title   : 'Dashboard',
        subtitle: 'Contact Center Dashboard',
        type    : 'group',
        icon    : 'heroicons_outline:home',
        children: [
            {
                id   : 'dashboards.project',
                title: 'Dashboard',
                type : 'basic',
                icon : 'mat_outline:space_dashboard',
                link : '/dashboards/manager',
                badge: {
                    title  : '7',
                    classes: 'px-2 bg-amber-600 text-white rounded-full',
                },
            },
                  

       
            // {
            //    id   : 'dashboards.project',
            //    title: 'Analytics & Optimization',
            //    type : 'collapsable',
            //    icon : 'heroicons_outline:presentation-chart-bar',
            //    link : '#',
            //     children: [
            //        {
            //            id        : 'apps.help-center.home',
            //            title     : 'Realtime Metrics',
            //            type      : 'basic',
            //            link      : 'apps/contacts',
            //            exactMatch: true,
            //        },
            //     ],
                
                
            //}
            
        ],
    },
    {
        id      : 'engagements',
        title   : 'CONTACT CENTER',
        subtitle: 'Live, past engagements and more...',
        type    : 'group',
        icon    : 'heroicons_outline:home',
        children: [
            {
                id   : 'cxhub.main',
                title: 'CX Control Hub',
                type : 'basic',
                icon : 'mat_outline:all_inclusive',
                link : '/cc/cx-hub',
                badge: {
                    title  : '144',
                    classes: 'px-2 bg-warn-600 text-white rounded-full',
                },
            },
            {
                id   : 'engagements.main',
                title: 'Engagements',
                type : 'basic',
                icon : 'heroicons_outline:chat-bubble-left-right',
                link : '/cc/engagements'
            }
          
        ],
    },
    {
        id      : 'cm',
        title   : 'Campaign Management',
        subtitle: 'Design Campaigns, Scripts and more...',
        type    : 'group',
        icon    : 'heroicons_outline:home',
        children: [
            
            {
                id   : 'cm.campaigns',
                title: 'Campaigns',
                type : 'basic',
                icon : 'heroicons_outline:cursor-arrow-ripple',
                link : '/cm/campaigns',
            },
            {
                id   : 'cm.segments',
                title: 'Segments',
                type : 'basic',
                icon : 'heroicons_outline:users',
                link : '/cm/segments',
            },
            {
                id   : 'cm.templates',
                title: 'Templates',
                type : 'basic',
                icon : 'heroicons_outline:document-text',
                link : '/cm/templates',
            },
          
        ],
    },
    {
        id      : 'settings',
        title   : 'Operation',
        subtitle: 'Agents, Departments and more',
        type    : 'group',
        icon    : 'heroicons_outline:home',
        children: [
            

            {
                id   : 'settings.user-management.home',
                title: 'Users',
                type : 'basic',
                icon : 'heroicons_outline:user-group',
                link : '/settings/user-management',
                exactMatch: true,
            },

            {
                id   : 'settings.user-management.teams',
                title: 'Teams',
                type : 'basic',
                icon : 'mat_outline:groups',
                link : '/settings/user-management/teams',
                exactMatch: true,
            },
            {
                id   : 'settings.user-management.rules',
                title: 'Rules',
                type : 'basic',
                icon : 'heroicons_outline:list-bullet',
                link : '/settings/user-management/rules',
                exactMatch: true,
            },
            // 
            // {
            //     id   : 'settings.user-management.permissions',
            //     title: 'Permissions',
            //     type : 'basic',
            //     icon : 'heroicons_outline:shield-check',
            //     link : '/settings/user-management/permissions',
            //     exactMatch: true,
            // },

            // {
            //     // id   : 'settings.user-management',
            //     // title: 'User Management',
            //     // type : 'collapsable',
            //     // icon : 'heroicons_outline:user-group',
            //     // link : '#',
            //     // children: [
             
            //         // {
            //         //     id   : 'settings.user-management.status-management',
            //         //     title: 'Manage Statuses',
            //         //     type : 'basic',
            //         //     link : '/settings/user-management/status-management',
            //         // },
            
            //         // {
            //         //     id   : 'settings.user-management.team-structure',
            //         //     title: 'Team Structure',
            //         //     type : 'basic',
            //         //     link : '/settings/user-management/team-structure',
            //         // }
            //     // ],
            // },

            {
                id      : 'settings.routing',
                title   : 'Routing',
                type    : 'collapsable',
                icon    : 'mat_outline:call_split',
                link : '#',
                children: [
                    // flows are modals to create usecases
                    // {
                    //     id        : 'settings.routing.home',
                    //     title     : 'Flows',
                    //     type      : 'basic',
                    //     link      : '/settings/routing',
                    //     exactMatch: true,
                    // },

                    // Prompts are audio options for calls/chat
                    // {
                    //     id   : 'settings.routing.prompts',
                    //     title: 'Prompts',
                    //     type : 'basic',
                    //     link : '/settings/routing/prompts',
                    // },
                    {
                        id   : 'settings.routing.queues',
                        title: 'Queues',
                        type : 'basic',
                        link : '/settings/routing/queues',
                    },
                    {
                        id   : 'settings.routing.operating-hours',
                        title: 'Hours of operation',
                        type : 'basic',
                        link : '/settings/routing/operating-hours',
                    },
                    // Quick connects are shortcuts to quickly connect to a user or a team
                    // {
                    //     id   : 'settings.routing.transfer-dests',
                    //     title: 'Quick Connects',
                    //     type : 'basic',
                    //     link : '/settings/routing/transfer-destinations',
                    // },
                ],
            },

            {
                id   : 'settings.channels',
                title: 'Channels',
                type : 'basic',
                icon : 'heroicons_solid:arrows-pointing-in',
                link : '/settings/user-management/channels',
                exactMatch: true,
            },

            
            // {
            //     id   : 'apps.file-manager',
            //     title: 'Outbound Campaign',
            //     type : 'basic',
            //     icon : 'heroicons_outline:newspaper',
            //     link : '/apps/file-manager',
            // },
           
          
        ],
    },

    {
        id      : 'company-settings',
        title   : 'Company',
        subtitle: 'Account, Billing, Help and more...',
        type    : 'group',
        icon    : 'heroicons_outline:home',
        children: [
            {
                id   : 'dashboards.project',
                title: 'Settings',
                type : 'basic',
                icon : 'heroicons_mini:cog',
                link : '/pages/settings',
                
            },
            {
                id      : 'apps.integrations',
                title   : 'Integrations',
                type    : 'basic',
                icon    : 'heroicons_outline:link',
                link    : '/apps/help-center'
            },
            {
                id      : 'apps.help-center',
                title   : 'Help Center',
                type    : 'collapsable',
                icon    : 'heroicons_outline:lifebuoy',
                link    : '/apps/help-center',
                children: [
                    // {
                    //     id        : 'apps.help-center.home',
                    //     title     : 'Home',
                    //     type      : 'basic',
                    //     link      : '/apps/help-center',
                    //     exactMatch: true,
                    // },
                    {
                        id   : 'apps.help-center.faqs',
                        title: 'FAQs',
                        type : 'basic',
                        link : '/apps/help-center/faqs',
                    },
                    // {
                    //     id   : 'apps.help-center.guides',
                    //     title: 'Guides',
                    //     type : 'basic',
                    //     link : '/apps/help-center/guides',
                    // },
                    {
                        id   : 'apps.help-center.support',
                        title: 'Support',
                        type : 'basic',
                        link : '/apps/help-center/support',
                    },
                ],
            },
            
           
            
        ],
    },
    
];

export const defaultNavigation: FuseNavigationItem[] = [
    {
        id      : 'support',
        title   : 'Support',
        subtitle: 'Account, Billing, Help and more...',
        type    : 'group',
        icon    : 'heroicons_outline:home',
        children: [
            {
                id      : 'apps.help-center',
                title   : 'Help Center',
                type    : 'collapsable',
                icon    : 'heroicons_outline:lifebuoy',
                link    : '/apps/help-center',
                children: [
                    // {
                    //     id        : 'apps.help-center.home',
                    //     title     : 'Home',
                    //     type      : 'basic',
                    //     link      : '/apps/help-center',
                    //     exactMatch: true,
                    // },
                    {
                        id   : 'apps.help-center.faqs',
                        title: 'FAQs',
                        type : 'basic',
                        link : '/apps/help-center/faqs',
                    },
                    // {
                    //     id   : 'apps.help-center.guides',
                    //     title: 'Guides',
                    //     type : 'basic',
                    //     link : '/apps/help-center/guides',
                    // },
                    {
                        id   : 'apps.help-center.support',
                        title: 'Support',
                        type : 'basic',
                        link : '/apps/help-center/support',
                    },
                ],
            },
        ],
    },
    
];

export const compactNavigation: FuseNavigationItem[] = [
    {
        id      : 'dashboards',
        title   : 'Dashboards',
        tooltip : 'Dashboards',
        type    : 'aside',
        icon    : 'heroicons_outline:home',
        children: [], // This will be filled from defaultNavigation so we don't have to manage multiple sets of the same navigation
    },
    {
        id      : 'apps',
        title   : 'Apps',
        tooltip : 'Apps',
        type    : 'aside',
        icon    : 'heroicons_outline:qrcode',
        children: [], // This will be filled from defaultNavigation so we don't have to manage multiple sets of the same navigation
    },
    {
        id      : 'pages',
        title   : 'Pages',
        tooltip : 'Pages',
        type    : 'aside',
        icon    : 'heroicons_outline:document-duplicate',
        children: [], // This will be filled from defaultNavigation so we don't have to manage multiple sets of the same navigation
    },
    {
        id      : 'user-interface',
        title   : 'UI',
        tooltip : 'UI',
        type    : 'aside',
        icon    : 'heroicons_outline:rectangle-stack',
        children: [], // This will be filled from defaultNavigation so we don't have to manage multiple sets of the same navigation
    },
    {
        id      : 'navigation-features',
        title   : 'Navigation',
        tooltip : 'Navigation',
        type    : 'aside',
        icon    : 'heroicons_outline:bars-3',
        children: [], // This will be filled from defaultNavigation so we don't have to manage multiple sets of the same navigation
    },
];
export const futuristicNavigation: FuseNavigationItem[] = [
    {
        id      : 'dashboards',
        title   : 'DASHBOARDS',
        type    : 'group',
        children: [], // This will be filled from defaultNavigation so we don't have to manage multiple sets of the same navigation
    },
    {
        id      : 'apps',
        title   : 'APPS',
        type    : 'group',
        children: [], // This will be filled from defaultNavigation so we don't have to manage multiple sets of the same navigation
    },
    {
        id   : 'others',
        title: 'OTHERS',
        type : 'group',
    },
    {
        id      : 'pages',
        title   : 'Pages',
        type    : 'aside',
        icon    : 'heroicons_outline:document-duplicate',
        children: [], // This will be filled from defaultNavigation so we don't have to manage multiple sets of the same navigation
    },
    {
        id      : 'user-interface',
        title   : 'User Interface',
        type    : 'aside',
        icon    : 'heroicons_outline:rectangle-stack',
        children: [], // This will be filled from defaultNavigation so we don't have to manage multiple sets of the same navigation
    },
    {
        id      : 'navigation-features',
        title   : 'Navigation Features',
        type    : 'aside',
        icon    : 'heroicons_outline:bars-3',
        children: [], // This will be filled from defaultNavigation so we don't have to manage multiple sets of the same navigation
    },
];
export const horizontalNavigation: FuseNavigationItem[] = [
    {
        id      : 'dashboards',
        title   : 'Dashboards',
        type    : 'group',
        icon    : 'heroicons_outline:home',
        children: [], // This will be filled from defaultNavigation so we don't have to manage multiple sets of the same navigation
    },
    {
        id      : 'apps',
        title   : 'Apps',
        type    : 'group',
        icon    : 'heroicons_outline:qrcode',
        children: [], // This will be filled from defaultNavigation so we don't have to manage multiple sets of the same navigation
    },
    {
        id      : 'pages',
        title   : 'Pages',
        type    : 'group',
        icon    : 'heroicons_outline:document-duplicate',
        children: [], // This will be filled from defaultNavigation so we don't have to manage multiple sets of the same navigation
    },
    {
        id      : 'user-interface',
        title   : 'UI',
        type    : 'group',
        icon    : 'heroicons_outline:rectangle-stack',
        children: [], // This will be filled from defaultNavigation so we don't have to manage multiple sets of the same navigation
    },
    {
        id      : 'navigation-features',
        title   : 'Misc',
        type    : 'group',
        icon    : 'heroicons_outline:bars-3',
        children: [], // This will be filled from defaultNavigation so we don't have to manage multiple sets of the same navigation
    },
];
