import { Injectable } from '@angular/core';
import { FuseMockApiService, FuseMockApiUtils } from '@fuse/lib/mock-api';
import { engagementStatuses as statusesData, channels as channelsData, engagements as engagementsData } from 'app/mock-api/dashboards/engagement/data';
import { assign, cloneDeep } from 'lodash-es';

@Injectable({providedIn: 'root'})
export class EngagementMockApi
{
    private _channels: any[] = channelsData;
    private _engagementStatuses: any[] = statusesData;
    private _engagements: any[] = engagementsData;

    /**
     * Constructor
     */
    constructor(private _fuseMockApiService: FuseMockApiService)
    {
        // Register Mock API handlers
        this.registerHandlers();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Register Mock API handlers
     */
    registerHandlers(): void
    {
        // -----------------------------------------------------------------------------------------------------
        // @ channels - GET
        // -----------------------------------------------------------------------------------------------------
        this._fuseMockApiService
            .onGet('api/dashboards/engagement/channels')
            .reply(() => [200, cloneDeep(this._channels)]);

        // -----------------------------------------------------------------------------------------------------
        // @ Brands - GET
        // -----------------------------------------------------------------------------------------------------
        this._fuseMockApiService
            .onGet('api/dashboards/engagement/statuses')
            .reply(() => [200, cloneDeep(this._engagementStatuses)]);

        // -----------------------------------------------------------------------------------------------------
        // @ Engagements - GET
        // -----------------------------------------------------------------------------------------------------
        this._fuseMockApiService
            .onGet('api/dashboards/engagement/engagements', 300)
            .reply(({request}) =>
            {
                // Get available queries
                const search = request.params.get('search');
                const sort = request.params.get('sort') || 'name';
                const order = request.params.get('order') || 'asc';
                const page = parseInt(request.params.get('page') ?? '1', 10);
                const size = parseInt(request.params.get('size') ?? '10', 10);

                // Clone the engagements
                let engagements: any[] | null = cloneDeep(this._engagements);

                // Sort the engagements
                if ( sort === 'channel' || sort === 'authenticated' )
                {
                    engagements.sort((a, b) =>
                    {
                        const fieldA = a[sort].toString().toUpperCase();
                        const fieldB = b[sort].toString().toUpperCase();
                        return order === 'asc' ? fieldA.localeCompare(fieldB) : fieldB.localeCompare(fieldA);
                    });
                }
                else
                {
                    engagements.sort((a, b) => order === 'asc' ? a[sort] - b[sort] : b[sort] - a[sort]);
                }

                // If search exists...
                if ( search )
                {
                    // Filter the engagements
                    engagements = engagements.filter(engagement => engagement.name && engagement.name.toLowerCase().includes(search.toLowerCase()));
                }

                // Paginate - Start
                const engagementsLength = engagements.length;

                // Calculate pagination details
                const begin = page * size;
                const end = Math.min((size * (page + 1)), engagementsLength);
                const lastPage = Math.max(Math.ceil(engagementsLength / size), 1);

                // Prepare the pagination object
                let pagination = {};

                // If the requested page number is bigger than
                // the last possible page number, return null for
                // engagements but also send the last possible page so
                // the app can navigate to there
                if ( page > lastPage )
                {
                    engagements = null;
                    pagination = {
                        lastPage,
                    };
                }
                else
                {
                    // Paginate the results by size
                    engagements = engagements.slice(begin, end);

                    // Prepare the pagination mock-api
                    pagination = {
                        length    : engagementsLength,
                        size      : size,
                        page      : page,
                        lastPage  : lastPage,
                        startIndex: begin,
                        endIndex  : end - 1,
                    };
                }

                // Return the response
                return [
                    200,
                    {
                        engagements,
                        pagination,
                    },
                ];
            });

        // -----------------------------------------------------------------------------------------------------
        // @ engagement - GET
        // -----------------------------------------------------------------------------------------------------
        this._fuseMockApiService
            .onGet('api/dashboards/engagement/engagements/engagement')
            .reply(({request}) =>
            {
                // Get the id from the params
                const id = request.params.get('id');
                console.log('ID=' + id);

                // Clone the engagements
                const engagements = cloneDeep(this._engagements);

                // Find the engagement
                const engagement = engagements.find(item => item.id === id);

                // Return the response
                return [200, engagement];
            });
        }
}
