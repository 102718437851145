import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { User } from 'app/core/user/user.types';
import { map, Observable, ReplaySubject, tap } from 'rxjs';

@Injectable({providedIn: 'root'})
export class UserService
{
    private _httpClient = inject(HttpClient);
    private _user: ReplaySubject<User> = new ReplaySubject<User>(1);

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Setter & getter for user
     *
     * @param value
     */
    set user(value: User)
    {
        // Store the value
        this._user.next(value);
    }

    get user$(): Observable<User>
    {
        return this._user.asObservable();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Get the current signed-in user data
     */
    get(): Observable<User>
    {
        return this._httpClient.get<User>('api/common/user').pipe(
            tap((user) =>
            {
                this._user.next(user);
            }),
        );
    }

    /**
     * Update the user
     *
     * @param user
     */
    update(user: User): Observable<any>
    {
        return this._httpClient.patch<User>('api/common/user', {user}).pipe(
            map((response) =>
            {
                this._user.next(response);
            }),
        );
    }
}




//import { HttpClient } from '@angular/common/http';
// import { inject, Injectable } from '@angular/core';
// import { User } from 'app/core/user/user.types';
// import { map, Observable, ReplaySubject, tap } from 'rxjs';
// import { fetchAuthSession, getCurrentUser,fetchUserAttributes } from 'aws-amplify/auth';
// import { AuthenticatorService } from '@aws-amplify/ui-angular';
// @Injectable({ providedIn: 'root' })
// export class UserService {
//     private _httpClient = inject(HttpClient);
//     public authenticator = inject(AuthenticatorService);
//     private _user: ReplaySubject<User> = new ReplaySubject<User>(1);

//     /**
//      * Constructor
//      */
//     constructor() {


//         this.getAuthenticatedUser()
//             // Auth.currentAuthenticatedUser()
//             .then(user => {
//                 console.log('USER DETAILS');
//                 console.log(JSON.stringify(user));
//                 //console.log('attributes:', user.attributes);
//                 this.user = {
//                     id: user.sub,
//                     name: user.name,
//                     email: user.email,
//                     given_name: user.given_name,
//                     family_name: user.family_name,
//                     groups: user["custom:groups"],
//                     avatar: '',
//                     //avatar: 'assets/images/avatars/male-01.jpg',
//                     status: 'online',
//                 };
//             })
//         //         .catch(err => console.log(err));
//     }

//     // -----------------------------------------------------------------------------------------------------
//     // @ Accessors
//     // -----------------------------------------------------------------------------------------------------

//     /**
//      * Setter & getter for user
//      *
//      * @param value
//      */
//     set user(value: User) {
//         // Store the value
//         this._user.next(value);
//     }

//     get user$(): Observable<User> {
//         return this._user.asObservable();
//     }

//     // -----------------------------------------------------------------------------------------------------
//     // @ Public methods
//     // -----------------------------------------------------------------------------------------------------

//     /**
//      * Get the current signed-in user data
//      */
//     get(): Observable<User> {
//         // return this._httpClient.get<User>('api/common/user').pipe(
//         //     tap((user) =>
//         //     {
//         //         this._user.next(user);
//         //     }),
//         // );

//         this.getAuthenticatedUser()
//             .then(user => {
//                 //console.log('attributes:', user.attributes);
//                 this.user = {
//                     id: user.sub,
//                     name: user.name,
//                     email: user.email,
//                     given_name: user.given_name,
//                     family_name: user.family_name,
//                     groups: user["custom:groups"],
//                     avatar: '',
//                     //avatar: 'assets/images/avatars/brian-hughes.jpg',
//                     status: 'online',
//                 };
//             })
//             .catch(err => console.log(err));

//         return this._user.asObservable();
//     }

//     /**
//      * Update the user
//      *
//      * @param user
//      */
//     update(user: User): Observable<any> {
//         return this._httpClient.patch<User>('api/common/user', { user }).pipe(
//             map((response) => {
//                 this._user.next(response);
//             }),
//         );
//     }

//     //Private methods

//     private getAuthenticatedUser = async () => {
//         const user = await fetchUserAttributes();
//         return user;
//         // const {
//         //   username,
//         //   signInDetails
//         // } = await getCurrentUser();

//         // const {
//         //   tokens: session
//         // } = await fetchAuthSession();

//         // // Note that session will no longer contain refreshToken and clockDrift
//         // return {
//         //   username,
//         //   session,
//         //   authenticationFlowType: signInDetails.authFlowType,
//         //   signInDetails
//         // };
//     }
// }
