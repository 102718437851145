/* eslint-disable */
export const routingProfiles = [
    {
        id      : 'b899ec30-b85a-40ab-bb1f-18a596d5c6de',
        parentId: null,
        name    : 'xcapital-complaints-profile',
        slug    : 'complaints',
    },
    {
        id      : '07986d93-d4eb-4de1-9448-2538407f7254',
        parentId: null,
        name    : 'xcapital-payments-profile',
        slug    : 'payments',
    },
    {
        id      : 'ad12aa94-3863-47f8-acab-a638ef02a3e9',
        parentId: null,
        name    : 'xcapital-support-profile',
        slug    : 'support',
    },
];
export const securityProfiles = [
    {
        id  : 'e1789f32-9475-43e7-9256-451d2e3a2282',
        name: 'Admin',
        slug: 'admin',
    },
    {
        id  : '61d52c2a-8947-4a2c-8c35-f36baef45b96',
        name: 'Manager',
        slug: 'manager',
    },
    {
        id  : 'f9987124-7ada-4b93-bef7-35280b3ddbd7',
        name: 'Agent',
        slug: 'agent',
    }
];
export const tags = [
    {
        id   : '167190fa-51b4-45fc-a742-8ce1b33d24ea',
        title: 'english',
    },
    {
        id   : '3baea410-a7d6-4916-b79a-bdce50c37f95',
        title: 'spanish',
    },
    {
        id   : '8ec8f60d-552f-4216-9f11-462b95b1d306',
        title: 'japanese',
    },
    {
        id   : '8837b93f-388b-43cc-851d-4ca8f23f3a61',
        title: 'chinese',
    }
];
export const usersHierarcies = [
    {
        id  : '987dd10a-43b1-49f9-bfd9-05bb2dbc7029',
        name: 'Complaints',
        slug: 'complaints',
    },
    {
        id  : '998b0c07-abfd-4ba3-8de1-7563ef3c4d57',
        name: 'Payments',
        slug: 'payments',
    },
    {
        id  : '05ebb527-d733-46a9-acfb-a4e4ec960024',
        name: 'Support',
        slug: 'support',
    },
];
export const users = [
    // {
    //     id         : ,
    //     category   : 'b899ec30-b85a-40ab-bb1f-18a596d5c6de',
    //     name       : 'Capmia Mens Chronograph Watch 44mm 5 ATM',
    //     description: 'Consequat esse in culpa commodo anim. Et ullamco anim amet est. Sunt dolore ex occaecat officia anim. In sit minim laborum nostrud. Consequat ex do velit voluptate do exercitation est adipisicing quis velit.',
    //     tags       : [
    //         '167190fa-51b4-45fc-a742-8ce1b33d24ea',
    //         '7d6dd47e-7472-4f8b-93d4-46c114c44533',
    //         '8837b93f-388b-43cc-851d-4ca8f23f3a61',
    //         '2300ac48-f268-466a-b765-8b878b6e14a7',
    //         'b1286f3a-e2d0-4237-882b-f0efc0819ec3',
    //     ],
    //     sku        : 'ETV-2425',
    //     barcode    : '8346201275534',
    //     brand      : '61d52c2a-8947-4a2c-8c35-f36baef45b96',
    //     vendor     : '998b0c07-abfd-4ba3-8de1-7563ef3c4d57',
    //     stock      : 30,
    //     reserved   : 5,
    //     cost       : 450.18,
    //     basePrice  : 1036,
    //     taxPercent : 30,
    //     price      : 1346.8,
    //     weight     : 0.61,
    //     thumbnail  : 'assets/images/apps/ecommerce/products/watch-01-thumb.jpg',
    //     images     : [
    //         'assets/images/apps/ecommerce/products/watch-01-01.jpg',
    //         'assets/images/apps/ecommerce/products/watch-01-02.jpg',
    //         'assets/images/apps/ecommerce/products/watch-01-03.jpg',
    //     ],
    //     active     : true,
    // },
    {
        id                  : '7eb7c859-1347-4317-96b6-9476a7e2ba31',
        login               : 'ak@ontree.io',
        firstname           : 'Arkadas',
        lastname            : 'Kilic',
        name                : 'Kilic Arkadas',
        routingprofile      : '07986d93-d4eb-4de1-9448-2538407f7254',
        securityprofile     : 'e1789f32-9475-43e7-9256-451d2e3a2282',
        usershierarcy       : '998b0c07-abfd-4ba3-8de1-7563ef3c4d57',
        acwtimeout          : '0',
        phonetype           : 'Soft phone',
        autoacceptcalls     : true,
        deskphonenumber     : '',
        tags                : [
            '167190fa-51b4-45fc-a742-8ce1b33d24ea',
            '8ec8f60d-552f-4216-9f11-462b95b1d306'
        ],
    },
    {
        id                  : '7eb7c859-1347-4317-96b6-9476a7e2ba32',
        login               : 'dk@ontree.io',
        firstname           : 'Dilek',
        lastname            : 'Kilic',
        name                : 'Kilic Dilek',
        routingprofile      : 'b899ec30-b85a-40ab-bb1f-18a596d5c6de',
        securityprofile     : '61d52c2a-8947-4a2c-8c35-f36baef45b96',
        usershierarcy       : '987dd10a-43b1-49f9-bfd9-05bb2dbc7029',
        acwtimeout          : '0',
        phonetype           : 'Soft phone',
        autoacceptcalls     : false,
        deskphonenumber     : '',
        tags                : [
            '167190fa-51b4-45fc-a742-8ce1b33d24ea',
            '8ec8f60d-552f-4216-9f11-462b95b1d306'
        ],
    },
    {
        id                  : '7eb7c859-1347-4317-96b6-9476a7e2ba33',
        login               : 'fatih@ontree.io',
        firstname           : 'Fatih',
        lastname            : 'Kaygusuz',
        name                : 'Kaygusuz Fatih',
        routingprofile      : 'b899ec30-b85a-40ab-bb1f-18a596d5c6de',
        securityprofile     : '61d52c2a-8947-4a2c-8c35-f36baef45b96',
        usershierarcy       : '987dd10a-43b1-49f9-bfd9-05bb2dbc7029',
        acwtimeout          : '0',
        phonetype           : 'Soft phone',
        autoacceptcalls     : false,
        deskphonenumber     : '',
        tags                : [
            '167190fa-51b4-45fc-a742-8ce1b33d24ea',
            '8ec8f60d-552f-4216-9f11-462b95b1d306'
        ],
    },
    {
        id                  : '7eb7c859-1347-4317-96b6-9476a7e2ba34',
        login               : 'ijaz@ontree.io',
        firstname           : 'Ijaz',
        lastname            : 'Jabbar',
        name                : 'Jabbar Ijaz',
        routingprofile      : '07986d93-d4eb-4de1-9448-2538407f7254',
        securityprofile     : 'e1789f32-9475-43e7-9256-451d2e3a2282',
        usershierarcy       : '998b0c07-abfd-4ba3-8de1-7563ef3c4d57',
        acwtimeout          : '0',
        phonetype           : 'Soft phone',
        autoacceptcalls     : false,
        deskphonenumber     : '',
        tags                : [
            '167190fa-51b4-45fc-a742-8ce1b33d24ea',
            '8ec8f60d-552f-4216-9f11-462b95b1d306'
        ],
    },
    {
        id                  : '7eb7c859-1347-4317-96b6-9476a7e2ba35',
        login               : 'jane@ontree.io',
        firstname           : 'Jane',
        lastname            : 'Doe',
        name                : 'Doe Jane',
        routingprofile      : 'ad12aa94-3863-47f8-acab-a638ef02a3e9',
        securityprofile     : 'f9987124-7ada-4b93-bef7-35280b3ddbd7',
        usershierarcy       : '05ebb527-d733-46a9-acfb-a4e4ec960024',
        acwtimeout          : '0',
        phonetype           : 'Soft phone',
        autoacceptcalls     : false,
        deskphonenumber     : '',
        tags                : [
            '167190fa-51b4-45fc-a742-8ce1b33d24ea',
            '8ec8f60d-552f-4216-9f11-462b95b1d306'
        ],
    },
    {
        id                  : '7eb7c859-1347-4317-96b6-9476a7e2ba36',
        login               : 'john@ontree.io',
        firstname           : 'John',
        lastname            : 'Doe',
        name                : 'Doe John',
        routingprofile      : 'ad12aa94-3863-47f8-acab-a638ef02a3e9',
        securityprofile     : 'f9987124-7ada-4b93-bef7-35280b3ddbd7',
        usershierarcy       : '05ebb527-d733-46a9-acfb-a4e4ec960024',
        acwtimeout          : '0',
        phonetype           : 'Soft phone',
        autoacceptcalls     : false,
        deskphonenumber     : '',
        tags                : [
            '167190fa-51b4-45fc-a742-8ce1b33d24ea',
            '8ec8f60d-552f-4216-9f11-462b95b1d306'
        ],
    },

];
