/* eslint-disable */
export const channels = [
    {
        id      : '1',
        parentId: null,
        name    : 'Call',
        slug    : 'call',
    },
    {
        id      : '2',
        parentId: null,
        name    : 'Chat',
        slug    : 'chat',
    },
    {
        id      : '3',
        parentId: null,
        name    : 'SMS',
        slug    : 'sms',
    },
    {
        id      : '4',
        parentId: null,
        name    : 'Email',
        slug    : 'email',
    },
    {
        id      : '5',
        parentId: null,
        name    : 'Whatsapp',
        slug    : 'whatsapp',
    },
    {
        id      : '6',
        parentId: null,
        name    : 'Facebook',
        slug    : 'facebook',
    },
    {
        id      : '7',
        parentId: null,
        name    : 'Task',
        slug    : 'task',
    },
];
export const engagementStatuses = [
    {
        id  : 'e1789f32-9475-43e7-9256-451d2e3a2282',
        name: 'Benton',
        slug: 'benton',
    },
    {
        id  : '61d52c2a-8947-4a2c-8c35-f36baef45b96',
        name: 'Capmia',
        slug: 'capmia',
    },
    {
        id  : 'f9987124-7ada-4b93-bef7-35280b3ddbd7',
        name: 'Lara',
        slug: 'lara',
    },
    {
        id  : '5913ee46-a497-41db-a118-ee506011529f',
        name: 'Premera',
        slug: 'premera',
    },
    {
        id  : '2c4d98d8-f334-4125-9596-862515f5526b',
        name: 'Zeon',
        slug: 'zeon',
    },
];

export const engagements = [
    {
        "id": "021890eb-9e03-4c20-aadd-a13731667ead",
        "DisconnectReason": "CUSTOMER_DISCONNECT",
        "PreviousContactId": "",
        "Recording": "",
        "CustomerVoiceActivity": "",
        "InitialContactId": "",
        "AWSContactTraceRecordFormatVersion": "2017-03-10",
        "LastUpdateTimestamp": "2024-03-13T23:58:45Z",
        "Recordings": "",
        "Tags": {
            "aws:connect:instanceId": "7367b767-6e49-4442-88fe-00be0f517c69",
            "aws:connect:systemEndpoint": "+442038854636"
        },
        "EntityType": "engagement",
        "CreatedDate": 1710374328135,
        "InstanceARN": "arn:aws:connect:eu-west-2:045493394446:instance/7367b767-6e49-4442-88fe-00be0f517c69",
        "SystemEndpoint": {
            "Address": "+442038854636",
            "Type": "TELEPHONE_NUMBER"
        },
        "InitiationTimestamp": "2024-03-13T23:57:36Z",
        "Attributes": {
            "customer_balance": "",
            "pay_now": "",
            "customer_name": "",
            "postcallRecordingImportEnabled": "true"
        },
        "MediaStreams": [
            {
                "Type": "AUDIO"
            }
        ],
        "VoiceIdResult": "",
        "ScheduledTimestamp": "",
        "AgentConnectionAttempts": 0,
        "ConnectedToSystemTimestamp": "2024-03-13T23:57:36Z",
        "ContactId": "d8c6d3d1-b9c6-4f90-99d3-7a4ef4206dd7",
        "InitiationMethod": "INBOUND",
        "TransferredToEndpoint": "",
        "SegmentAttributes": {
            "connect:Subtype": {
                "ValueString": "connect:Telephony"
            }
        },
        "Channel": "VOICE",
        "TaskTemplateInfo": "",
        "TransferCompletedTimestamp": "",
        "References": [],
        "NextContactId": "",
        "CustomerEndpoint": {
            "Address": "+447850646492",
            "Type": "TELEPHONE_NUMBER"
        },
        "DisconnectTimestamp": "2024-03-13T23:57:40Z",
        "AnsweringMachineDetectionStatus": "",
        "Queue": "",
        "ContactDetails": {},
        "Campaign": {
            "CampaignId": ""
        },
        "Agent": "",
        "AWSAccountId": "045493394446"
    },
    {
        "id": "6199bee6-e230-40df-aa87-bb6c292d7bff",
        "DisconnectReason": "CUSTOMER_DISCONNECT",
        "PreviousContactId": "",
        "Recording": {
            "DeletionReason": "",
            "Status": "AVAILABLE",
            "Type": "CHAT",
            "Location": "c3-prd-connect-045493394446-eu-west-2/CHAT_TRANSCRIPTS/2024/03/14/6199bee6-e230-40df-aa87-bb6c292d7bff_20240314T00:27_UTC.json"
        },
        "CustomerVoiceActivity": "",
        "InitialContactId": "",
        "AWSContactTraceRecordFormatVersion": "2017-03-10",
        "LastUpdateTimestamp": "2024-03-14T00:28:43Z",
        "Recordings": [
            {
                "MediaStreamType": "CHAT",
                "Status": "AVAILABLE",
                "FragmentStopNumber": "",
                "StorageType": "S3",
                "StopTimestamp": "",
                "StartTimestamp": "",
                "DeletionReason": "",
                "ParticipantType": "ALL",
                "FragmentStartNumber": "",
                "Location": "c3-prd-connect-045493394446-eu-west-2/CHAT_TRANSCRIPTS/2024/03/14/6199bee6-e230-40df-aa87-bb6c292d7bff_20240314T00:27_UTC.json"
            }
        ],
        "Tags": {
            "aws:connect:instanceId": "7367b767-6e49-4442-88fe-00be0f517c69"
        },
        "EntityType": "engagement",
        "CreatedDate": 1710383690067,
        "InstanceARN": "arn:aws:connect:eu-west-2:045493394446:instance/7367b767-6e49-4442-88fe-00be0f517c69",
        "SystemEndpoint": "",
        "InitiationTimestamp": "2024-03-14T00:27:14Z",
        "Attributes": {
            "card_number": "",
            "postcallRecordingImportEnabled": "true",
            "authentication_status": "AUTHENTICATED",
            "customer_balance": "",
            "pay_now": "",
            "customer_name": "",
            "customer_id": "200001"
        },
        "MediaStreams": [
            {
                "Type": "CHAT"
            }
        ],
        "VoiceIdResult": "",
        "ScheduledTimestamp": "",
        "AgentConnectionAttempts": 0,
        "ConnectedToSystemTimestamp": "2024-03-14T00:27:14Z",
        "ContactId": "6199bee6-e230-40df-aa87-bb6c292d7bff",
        "InitiationMethod": "API",
        "TransferredToEndpoint": "",
        "SegmentAttributes": {
            "connect:Subtype": {
                "ValueString": "connect:Chat"
            }
        },
        "Channel": "CHAT",
        "TaskTemplateInfo": "",
        "TransferCompletedTimestamp": "",
        "References": [],
        "NextContactId": "",
        "CustomerEndpoint": "",
        "DisconnectTimestamp": "2024-03-14T00:27:38Z",
        "AnsweringMachineDetectionStatus": "",
        "Queue": "",
        "ContactDetails": {},
        "Campaign": {
            "CampaignId": ""
        },
        "Agent": "",
        "AWSAccountId": "045493394446"
    },

    {
        "id": "2042585a-7b97-4352-a04a-ef7b81ef905a",
        "DisconnectReason": "CUSTOMER_DISCONNECT",
        "PreviousContactId": "",
        "Recording": {
            "DeletionReason": "",
            "Status": "AVAILABLE",
            "Type": "CHAT",
            "Location": "c3-prd-connect-045493394446-eu-west-2/CHAT_TRANSCRIPTS/2024/03/14/6199bee6-e230-40df-aa87-bb6c292d7bff_20240314T00:27_UTC.json"
        },
        "CustomerVoiceActivity": "",
        "InitialContactId": "",
        "AWSContactTraceRecordFormatVersion": "2017-03-10",
        "LastUpdateTimestamp": "2024-03-14T00:28:43Z",
        "Recordings": [
            {
                "MediaStreamType": "CHAT",
                "Status": "AVAILABLE",
                "FragmentStopNumber": "",
                "StorageType": "S3",
                "StopTimestamp": "",
                "StartTimestamp": "",
                "DeletionReason": "",
                "ParticipantType": "ALL",
                "FragmentStartNumber": "",
                "Location": "c3-prd-connect-045493394446-eu-west-2/CHAT_TRANSCRIPTS/2024/03/14/6199bee6-e230-40df-aa87-bb6c292d7bff_20240314T00:27_UTC.json"
            }
        ],
        "Tags": {
            "aws:connect:instanceId": "7367b767-6e49-4442-88fe-00be0f517c69"
        },
        "EntityType": "engagement",
        "CreatedDate": 1710380786600,
        "InstanceARN": "arn:aws:connect:eu-west-2:045493394446:instance/7367b767-6e49-4442-88fe-00be0f517c69",
        "SystemEndpoint": "",
        "InitiationTimestamp": "2024-03-14T00:27:14Z",
        "Attributes": {
            "card_number": "",
            "postcallRecordingImportEnabled": "true",
            "authentication_status": "AUTHENTICATED",
            "customer_balance": "",
            "pay_now": "",
            "customer_name": "",
            "customer_id": "200001"
        },
        "MediaStreams": [
            {
                "Type": "CHAT"
            }
        ],
        "VoiceIdResult": "",
        "ScheduledTimestamp": "",
        "AgentConnectionAttempts": 0,
        "ConnectedToSystemTimestamp": "2024-03-14T00:27:14Z",
        "ContactId": "6199bee6-e230-40df-aa87-bb6c292d7bff",
        "InitiationMethod": "API",
        "TransferredToEndpoint": "",
        "SegmentAttributes": {
            "connect:Subtype": {
                "ValueString": "connect:Chat"
            }
        },
        "Channel": "EMAIL",
        "TaskTemplateInfo": "",
        "TransferCompletedTimestamp": "",
        "References": [],
        "NextContactId": "",
        "CustomerEndpoint": "",
        "DisconnectTimestamp": "2024-03-14T00:27:38Z",
        "AnsweringMachineDetectionStatus": "",
        "Queue": "",
        "ContactDetails": {},
        "Campaign": {
            "CampaignId": ""
        },
        "Agent": "",
        "AWSAccountId": "045493394446"
    },
    {
        "id": "da234e22-9bcf-4907-ad2a-948d8f9c4254",
        "DisconnectReason": "",
        "PreviousContactId": "",
        "Recording": {
            "DeletionReason": "",
            "Status": "AVAILABLE",
            "Type": "CHAT",
            "Location": "c3-prd-connect-045493394446-eu-west-2/CHAT_TRANSCRIPTS/2024/03/14/da234e22-9bcf-4907-ad2a-948d8f9c4254_20240314T12:09_UTC.json"
        },
        "CustomerVoiceActivity": "",
        "InitialContactId": "",
        "AWSContactTraceRecordFormatVersion": "2017-03-10",
        "LastUpdateTimestamp": "2024-03-14T12:11:41Z",
        "Recordings": [
            {
                "MediaStreamType": "CHAT",
                "Status": "AVAILABLE",
                "FragmentStopNumber": "",
                "StorageType": "S3",
                "StopTimestamp": "",
                "StartTimestamp": "",
                "DeletionReason": "",
                "ParticipantType": "ALL",
                "FragmentStartNumber": "",
                "Location": "c3-prd-connect-045493394446-eu-west-2/CHAT_TRANSCRIPTS/2024/03/14/da234e22-9bcf-4907-ad2a-948d8f9c4254_20240314T12:09_UTC.json"
            }
        ],
        "Tags": {
            "aws:connect:instanceId": "7367b767-6e49-4442-88fe-00be0f517c69"
        },
        "EntityType": "engagement",
        "CreatedDate": 1710418303284,
        "InstanceARN": "arn:aws:connect:eu-west-2:045493394446:instance/7367b767-6e49-4442-88fe-00be0f517c69",
        "SystemEndpoint": "",
        "InitiationTimestamp": "2024-03-14T12:09:34Z",
        "Attributes": {},
        "MediaStreams": [
            {
                "Type": "CHAT"
            }
        ],
        "VoiceIdResult": "",
        "ScheduledTimestamp": "",
        "AgentConnectionAttempts": 0,
        "ConnectedToSystemTimestamp": "2024-03-14T12:09:34Z",
        "ContactId": "da234e22-9bcf-4907-ad2a-948d8f9c4254",
        "InitiationMethod": "API",
        "TransferredToEndpoint": "",
        "SegmentAttributes": {
            "connect:Subtype": {
                "ValueString": "connect:Chat"
            }
        },
        "Channel": "SMS",
        "TaskTemplateInfo": "",
        "TransferCompletedTimestamp": "",
        "References": [],
        "NextContactId": "",
        "CustomerEndpoint": "",
        "DisconnectTimestamp": "2024-03-14T12:10:34Z",
        "AnsweringMachineDetectionStatus": "",
        "Queue": "",
        "ContactDetails": {},
        "Campaign": {
            "CampaignId": ""
        },
        "Agent": "",
        "AWSAccountId": "045493394446"
    },
    {
        "id": "86ccfd8f-878a-41f1-bc48-8e954fa1d212",
        "DisconnectReason": "CUSTOMER_DISCONNECT",
        "PreviousContactId": "",
        "Recording": "",
        "CustomerVoiceActivity": "",
        "InitialContactId": "",
        "AWSContactTraceRecordFormatVersion": "2017-03-10",
        "LastUpdateTimestamp": "2024-03-13T23:58:08Z",
        "Recordings": "",
        "Tags": {
            "aws:connect:instanceId": "7367b767-6e49-4442-88fe-00be0f517c69",
            "aws:connect:systemEndpoint": "+442038854636"
        },
        "EntityType": "engagement",
        "CreatedDate": 1710374291675,
        "InstanceARN": "arn:aws:connect:eu-west-2:045493394446:instance/7367b767-6e49-4442-88fe-00be0f517c69",
        "SystemEndpoint": {
            "Address": "+442038854636",
            "Type": "TELEPHONE_NUMBER"
        },
        "InitiationTimestamp": "2024-03-13T23:56:55Z",
        "Attributes": {
            "customer_balance": "",
            "pay_now": "",
            "customer_name": "",
            "postcallRecordingImportEnabled": "true"
        },
        "MediaStreams": [
            {
                "Type": "AUDIO"
            }
        ],
        "VoiceIdResult": "",
        "ScheduledTimestamp": "",
        "AgentConnectionAttempts": 0,
        "ConnectedToSystemTimestamp": "2024-03-13T23:56:55Z",
        "ContactId": "0b3604bf-ecf6-4542-9480-6205b91c9395",
        "InitiationMethod": "INBOUND",
        "TransferredToEndpoint": "",
        "SegmentAttributes": {
            "connect:Subtype": {
                "ValueString": "connect:Telephony"
            }
        },
        "Channel": "TASK",
        "TaskTemplateInfo": "",
        "TransferCompletedTimestamp": "",
        "References": [],
        "NextContactId": "",
        "CustomerEndpoint": {
            "Address": "+447850646492",
            "Type": "TELEPHONE_NUMBER"
        },
        "DisconnectTimestamp": "2024-03-13T23:56:59Z",
        "AnsweringMachineDetectionStatus": "",
        "Queue": "",
        "ContactDetails": {},
        "Campaign": {
            "CampaignId": ""
        },
        "Agent": "",
        "AWSAccountId": "045493394446"
    },
    {
        "id": "28c00386-ccbf-4b78-9c68-1c8b35be63ce",
        "DisconnectReason": "",
        "PreviousContactId": "",
        "Recording": {
            "DeletionReason": "",
            "Status": "AVAILABLE",
            "Type": "CHAT",
            "Location": "c3-prd-connect-045493394446-eu-west-2/CHAT_TRANSCRIPTS/2024/03/14/28c00386-ccbf-4b78-9c68-1c8b35be63ce_20240314T02:30_UTC.json"
        },
        "CustomerVoiceActivity": "",
        "InitialContactId": "",
        "AWSContactTraceRecordFormatVersion": "2017-03-10",
        "LastUpdateTimestamp": "2024-03-14T02:31:58Z",
        "Recordings": [
            {
                "MediaStreamType": "CHAT",
                "Status": "AVAILABLE",
                "FragmentStopNumber": "",
                "StorageType": "S3",
                "StopTimestamp": "",
                "StartTimestamp": "",
                "DeletionReason": "",
                "ParticipantType": "ALL",
                "FragmentStartNumber": "",
                "Location": "c3-prd-connect-045493394446-eu-west-2/CHAT_TRANSCRIPTS/2024/03/14/28c00386-ccbf-4b78-9c68-1c8b35be63ce_20240314T02:30_UTC.json"
            }
        ],
        "Tags": {
            "aws:connect:instanceId": "7367b767-6e49-4442-88fe-00be0f517c69"
        },
        "EntityType": "engagement",
        "CreatedDate": 1710383519786,
        "InstanceARN": "arn:aws:connect:eu-west-2:045493394446:instance/7367b767-6e49-4442-88fe-00be0f517c69",
        "SystemEndpoint": "",
        "InitiationTimestamp": "2024-03-14T02:30:37Z",
        "Attributes": {
            "card_number": "",
            "postcallRecordingImportEnabled": "true",
            "authentication_status": "",
            "customer_balance": "",
            "pay_now": "",
            "customer_name": "",
            "customer_id": ""
        },
        "MediaStreams": [
            {
                "Type": "CHAT"
            }
        ],
        "VoiceIdResult": "",
        "ScheduledTimestamp": "",
        "AgentConnectionAttempts": 0,
        "ConnectedToSystemTimestamp": "2024-03-14T02:30:37Z",
        "ContactId": "28c00386-ccbf-4b78-9c68-1c8b35be63ce",
        "InitiationMethod": "API",
        "TransferredToEndpoint": "",
        "SegmentAttributes": {
            "connect:Subtype": {
                "ValueString": "connect:Chat"
            }
        },
        "Channel": "WHATSAPP",
        "TaskTemplateInfo": "",
        "TransferCompletedTimestamp": "",
        "References": [],
        "NextContactId": "",
        "CustomerEndpoint": "",
        "DisconnectTimestamp": "2024-03-14T02:30:50Z",
        "AnsweringMachineDetectionStatus": "",
        "Queue": "",
        "ContactDetails": {},
        "Campaign": {
            "CampaignId": ""
        },
        "Agent": "",
        "AWSAccountId": "045493394446"
    },
    {
        "id": "2d2d5f35-0e0a-4749-8ecc-da1d6eeb295c",
        "DisconnectReason": "",
        "PreviousContactId": "",
        "Recording": {
            "DeletionReason": "",
            "Status": "AVAILABLE",
            "Type": "CHAT",
            "Location": "c3-prd-connect-045493394446-eu-west-2/CHAT_TRANSCRIPTS/2024/03/14/2d2d5f35-0e0a-4749-8ecc-da1d6eeb295c_20240314T12:08_UTC.json"
        },
        "CustomerVoiceActivity": "",
        "InitialContactId": "",
        "AWSContactTraceRecordFormatVersion": "2017-03-10",
        "LastUpdateTimestamp": "2024-03-14T12:10:15Z",
        "Recordings": [
            {
                "MediaStreamType": "CHAT",
                "Status": "AVAILABLE",
                "FragmentStopNumber": "",
                "StorageType": "S3",
                "StopTimestamp": "",
                "StartTimestamp": "",
                "DeletionReason": "",
                "ParticipantType": "ALL",
                "FragmentStartNumber": "",
                "Location": "c3-prd-connect-045493394446-eu-west-2/CHAT_TRANSCRIPTS/2024/03/14/2d2d5f35-0e0a-4749-8ecc-da1d6eeb295c_20240314T12:08_UTC.json"
            }
        ],
        "Tags": {
            "aws:connect:instanceId": "7367b767-6e49-4442-88fe-00be0f517c69"
        },
        "EntityType": "engagement",
        "CreatedDate": 1710418218227,
        "InstanceARN": "arn:aws:connect:eu-west-2:045493394446:instance/7367b767-6e49-4442-88fe-00be0f517c69",
        "SystemEndpoint": "",
        "InitiationTimestamp": "2024-03-14T12:08:45Z",
        "Attributes": {
            "card_number": "",
            "postcallRecordingImportEnabled": "true",
            "authentication_status": "AUTHENTICATED",
            "customer_balance": "",
            "pay_now": "",
            "customer_name": "",
            "customer_id": "200001"
        },
        "MediaStreams": [
            {
                "Type": "CHAT"
            }
        ],
        "VoiceIdResult": "",
        "ScheduledTimestamp": "",
        "AgentConnectionAttempts": 0,
        "ConnectedToSystemTimestamp": "2024-03-14T12:08:45Z",
        "ContactId": "2d2d5f35-0e0a-4749-8ecc-da1d6eeb295c",
        "InitiationMethod": "API",
        "TransferredToEndpoint": "",
        "SegmentAttributes": {
            "connect:Subtype": {
                "ValueString": "connect:Chat"
            }
        },
        "Channel": "FACEBOOK",
        "TaskTemplateInfo": "",
        "TransferCompletedTimestamp": "",
        "References": [],
        "NextContactId": "",
        "CustomerEndpoint": "",
        "DisconnectTimestamp": "2024-03-14T12:09:08Z",
        "AnsweringMachineDetectionStatus": "",
        "Queue": "",
        "ContactDetails": {},
        "Campaign": {
            "CampaignId": ""
        },
        "Agent": "",
        "AWSAccountId": "045493394446"
    }
];
